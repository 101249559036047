<template>
  <div class="s-content content">
    <main class="row content__page">
      <section class="column large-full">
        <div class="row add-bottom">
          <div class="column large-full">
            <div class="row">
              <div class="column large-6">
                <h3>Your Works</h3>
              </div>
              <div class="column large-6">
                <div class="ss-custom-select">
                  <select class="full-width" @change="changeType">
                    <option value="0">Post</option>
                    <option value="2">Draft</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Created</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in this.dataList">
                    <td :value="data.id" @click="update">{{ data.title }}</td>
                    <td>{{ data.typeName }}</td>
                    <td :value="data.id" @click="changeVisibility">{{ data.statusName }}</td>
                    <td>{{ data.createdTime }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div> 
      </section>
    </main>
    <div class="row">
      <div class="column large-full">
        <nav class="pgn">
          <ul>
            <li><a class="pgn__prev" href="#" v-if="paging.page > 1">Prev</a></li>
            <li>
              <a class="pgn__num" href="#" v-for="index in paging.pageList" :class="{'current': index === paging.page, 'dots': index === 0}" :value="index">
                {{ index !== 0 ? index : '...' }}
              </a>
            </li>
            <li><a class="pgn__next" href="#" v-if="paging.page < paging.totalPage">Next</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import Constants from '@/assets/js/constants';

export default {
  name: 'Draft',
  data() {
    return {
      dataList: [],
      dataMap: new Map(),
      type: 0,
      paging: {
        page: 1,
        pageSize: 10,
        totalPage: 1,
        pageList: [],
      }
    }
  },
  mounted() {
    this.$nextTick(function() {
      this.$func.loading(
        this.loadData
      );
    });
  },
  methods: {

    loadData: async function() {
      let data;
      if (this.type === Constants.POST_TYPE.POST) {
        data = this.getPostList();
      } else if (this.type === Constants.POST_TYPE.DRAFT) {
        data = this.getDraftList();
      }
      data.then(response => {
          this.dataList = response.data.data.items.map(item => {
            item['typeName'] = this.getTypeName(item.type);
            item['statusName'] = this.getStatusName(item.status);
            this.dataMap.set(item.id, item);
            return item;
          });
          this.paging.page = response.data.data.page;
          this.paging.totalPage = response.data.data.totalPage;
          this.$func.setPageList(this.paging);
        })
        .catch(error => {
          this.$func.showErrorPopUpV1(error);
        });
    },

    getPostList: async function() {
      let params = {
        params: {
          page: this.paging.page,
          pageSize: this.paging.pageSize,
          showAll: true
        }
      }
      let axios = this.$func.getAxios();
      let data = await axios.get('/post', params);
      return data;
    },

    getDraftList: async function() {
      let params = {
        params: {
          page: this.paging.page,
          pageSize: this.paging.pageSize
        }
      }
      let axios = this.$func.getAxios();
      let data = await axios.get('/draft', params);
      return data;
    },

    update: function(e) {
      if (this.type === Constants.POST_TYPE.POST) return;
      let draftId = Number(e.target.getAttribute('value'));
      let slug = this.dataMap.get(draftId);
      slug = slug ? slug.slug : '';
      let axios = this.$func.getAxios();
      axios.get('/draft/'.concat(draftId))
        .then(response => {
          let data = response.data.data;
          let params = {
            slug: slug,
            id: data.id,
            type: data.type,
            title: data.title,
            content: data.content,
            thumbnail: data.thumbnail,
            selectedTags: data.tags ? JSON.stringify(data.tags)  : '[]'
          }
          this.$router.push({
            name: 'Writer',
            params: params
          });
        })
        .catch(error => {
          this.$func.showErrorPopUpV1(error);
        });
    },

    changeVisibility: function(e) {
      if (this.type === Constants.POST_TYPE.DRAFT) return;
      let id = Number(e.target.getAttribute('value'));
      let api = '/post/'.concat(id);
      let axios = this.$func.getAxios();
      axios.patch(api)
        .then(response => {
          this.dataList.map(item => {
            if (item.id !== id) return item;
            if (item.status === Constants.STATUS.ACTIVATED) {
              item.status = Constants.STATUS.INACTIVATED;
            } else {
              item.status = Constants.STATUS.ACTIVATED;
            }
            item.statusName = this.getStatusName(item.status);
            return item;
          });
        })
        .catch(error => {
          this.$func.showErrorPopUpV1(error);
        });
    },

    changeType: function(e) {
      this.type = Number(e.target.value);
      this.paging = {
        page: 1,
        pageSize: 10,
        totalPage: 1,
        pageList: [],
      }
      this.$func.loading(
        this.loadData
      );
    },

    getTypeName: function(type) {
      switch(type) {
        case Constants.POST_TYPE.POST: {
          return 'Post';
        }
        case Constants.POST_TYPE.QUOTE: {
          return 'Quote';
        }
        case Constants.POST_TYPE.DRAFT: {
          return 'Draft';
        }
      }
      return '-';
    },

    getStatusName: function(status) {
      switch(status) {
        case Constants.STATUS.INACTIVATED: {
          return 'Hidden';
        }
        case Constants.STATUS.ACTIVATED: {
          return 'Visible';
        }
      }
      return '-';
    }

  }
}
</script>

<style scoped>
tbody > tr > td:first-child,
tbody > tr > td:nth-child(3) {
  cursor: pointer !important;
}
.ss-custom-select select {
  padding: 0 !important;
  height: 3rem !important;
  line-height: 2rem !important;
}
</style>
