import axios from "axios";
import swal from "sweetalert2";

var variables = {
  timeOut: 5000,
  isScriptLoaded: false,
  scriptList: [
    '/assets/js/modernizr.js',
    '/assets/js/jquery-3.2.1.min.js',
    '/assets/js/plugins.js',
    '/assets/js/main.js'
  ]
}

export const func = {

  defaultImages: [
    {
      src: '/assets/images/thumbs/masonry/woodcraft-600.jpg',
      srcset: '/assets/images/thumbs/masonry/woodcraft-600.jpg 1x, /assets/images/thumbs/masonry/woodcraft-1200.jpg 2x'
    },
    {
      src: '/assets/images/thumbs/masonry/tulips-600.jpg',
      srcset: '/assets/images/thumbs/masonry/tulips-600.jpg 1x, /assets/images/thumbs/masonry/tulips-1200.jpg 2x'
    },
    {
      src: '/assets/images/thumbs/masonry/dew-600.jpg',
      srcset: '/assets/images/thumbs/masonry/dew-600.jpg 1x, /assets/images/thumbs/masonry/dew-1200.jpg 2x'
    },
    {
      src: '/assets/images/thumbs/masonry/rucksack-600.jpg',
      srcset: '/assets/images/thumbs/masonry/rucksack-600.jpg 1x, /assets/images/thumbs/masonry/rucksack-1200.jpg 2x'
    },
    {
      src: '/assets/images/thumbs/masonry/jump-600.jpg',
      srcset: '/assets/images/thumbs/masonry/jump-600.jpg 1x, /assets/images/thumbs/masonry/jump-1200.jpg 2x'
    },
    {
      src: '/assets/images/thumbs/masonry/guitarist-600.jpg',
      srcset: '/assets/images/thumbs/masonry/guitarist-600.jpg 1x, /assets/images/thumbs/masonry/guitarist-1200.jpg 2x'
    },
    {
      src: '/assets/images/thumbs/masonry/beetle-600.jpg',
      srcset: '/assets/images/thumbs/masonry/beetle-600.jpg 1x, /assets/images/thumbs/masonry/beetle-1200.jpg 2x'
    },
    {
      src: '/assets/images/thumbs/masonry/cookies-600.jpg',
      srcset: '/assets/images/thumbs/masonry/cookies-600.jpg 1x, /assets/images/thumbs/masonry/cookies-1200.jpg 2x'
    },
    {
      src: '/assets/images/thumbs/masonry/lamp-600.jpg',
      srcset: '/assets/images/thumbs/masonry/lamp-600.jpg 1x, /assets/images/thumbs/masonry/lamp-1200.jpg 2x'
    },
    {
      src: '/assets/images/thumbs/masonry/gallery/slide-1-600.jpg',
      srcset: '/assets/images/thumbs/masonry/gallery/slide-1-600.jpg 1x, /assets/images/thumbs/masonry/gallery/slide-1-1200.jpg 2x'
    },
    {
      src: '/assets/images/thumbs/masonry/walk-600.jpg',
      srcset: '/assets/images/thumbs/masonry/walk-600.jpg 1x, /assets/images/thumbs/masonry/walk-1200.jpg 2x'
    },
    {
      src: '/assets/images/thumbs/masonry/real-600.jpg',
      srcset: '/assets/images/thumbs/masonry/real-600.jpg 1x, /assets/images/thumbs/masonry/real-1200.jpg 2x'
    }
  ],

  // Load all needed JS files
  loadSrcipt: function() {
    if (!variables.isScriptLoaded) {
      for (let i = 0; i < variables.scriptList.length; i++) {
        let script = document.createElement("script");
        script.src = variables.scriptList[i];
        script.async = false;
        document.head.append(script);
      }
      variables.isScriptLoaded = true;
    }
  },

  // Load all images
  loadImages: function() {
    return new Promise((resolve, reject) => {
      let images = document.querySelectorAll('.s-content img');
      let unloadedImg = images.length;
      if (unloadedImg == 0) resolve('Done');
      images.forEach(img => {
        img.addEventListener('load', () => {
          unloadedImg--;
        })
      });
      let time = 0;
      let timer = setInterval(function() {
        if (unloadedImg < 1) {
          clearInterval(timer);
          resolve('Done');
        }
        if (time > variables.timeOut) {
          clearInterval(timer);
          reject('Error');
        }
        time += 100;
      }, 100);
    });
  },

  // Display loading screen before the page is completely loaded
  loading: async function(...args) {
    let loader = document.querySelector("#preloader");
    let opacity = 1;
    loader.style.opacity = opacity;
    loader.style.display = 'block';
    // Loading
    this.loadSrcipt();
    if (args.length > 0) {
      for (let arg of args) {
        try {
          await arg();
        } catch (error) {
          if (process.env.VUE_APP_PROFILE == 'local') {
            console.log("[ERROR] " + error);
          }
        }
      }
    }
    // Fade out the loading screen
    let timer = setInterval(function() {
      if (opacity < 0.1) {
        loader.style.display = 'none';
        clearInterval(timer);
        return;
      }
      opacity -= 0.02;
      loader.style.opacity = opacity;
    }, 10);
  },

  ssMasonryFolio: function() {
    var containerBricks = $('.masonry');
    containerBricks.masonry({
      itemSelector: '.masonry__brick',
      columnWidth: '.grid-sizer',
      percentPosition: true,
      resize: true
    });
    // layout Masonry after each image loads
    containerBricks.imagesLoaded().progress( function() {
      containerBricks.masonry('layout');
    });
   },

  ssBricksAnimate: function() {
    var animateEl = $('.animate-this');
    setTimeout(function() {
      animateEl.each(function(ctr) {
        var el = $(this);
        setTimeout(function() {
          el.addClass('animated');
        }, ctr * 100);
      });
    }, 200);
    $(window).on('resize', function() {
      // remove animation classes
      animateEl.removeClass('animate-this animated');
    });
  },

  getAxios: function() {
    let token = localStorage.getItem('token');
    let headers = {
      'Content-Type': 'application/json'
    };
    if (token) {
      headers['Authorization'] = token;
    }
    let config = {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        get: headers,
        post: headers,
        put: headers,
        patch: headers
      }
    }
    return axios.create(config);
  },

  showErrorPopUp: function(error) {
    return swal.fire({
      title: 'Oops!',
      text: error.response.data.message,
      icon: 'error',
      confirmButtonColor: '#000000'
    });
  },

  showErrorPopUpV1: function(error) {
    let message = error.response.data.errorList[0]
      .replace(new RegExp('\\[[a-z]+\\] ', 'g'), '');
    return swal.fire({
      title: 'Oops',
      text: message,
      icon: 'error',
      confirmButtonColor: '#000000'
    });
  },

  openSearchForm: function(e) {
    var searchWrap = $('.header__search'),
        siteBody = $('body');

    e.preventDefault();
    e.stopPropagation();
    siteBody.addClass('search-is-visible');
    setTimeout(function() {
      searchWrap.find('.header__search-field').focus();
    }, 100);
  },

  openLoginForm: function(e) {
    var loginWrap = $('.header__login'),
        siteBody = $('body');

    e.preventDefault();
    e.stopPropagation();
    siteBody.css({'overflow': 'hidden'});
    loginWrap.addClass('login-is-visible');
    setTimeout(function() {
      loginWrap.find('.header__login-username').focus();
    }, 100);
  },

  closeLoginForm: function() {
    $('.header__login .header__login-close').trigger('click');
  },

  checkAuthorization: function() {
    let isAuthorized = false;
    let token = localStorage.getItem('token');
    let userInfo = localStorage.getItem('userInfo');
    if (token && userInfo) {
      let now = new Date().getTime();
      let expiredTime = JSON.parse(userInfo).expiredTime;
      isAuthorized = expiredTime > now;
    } else {
      isAuthorized = false;
    }
    if (!isAuthorized) {
      localStorage.removeItem('userInfo');
    }
    return isAuthorized;
  },

  setPageList: function(paging) {
    paging.pageList = [];
    let previousPage = paging.page - 2 > 0 ? paging.page - 2 : 1;
    let nextPage = previousPage + 4 <= paging.totalPage ? previousPage + 4 : paging.totalPage;
    for (let i = previousPage; i <= nextPage; i++) {
      if (i === previousPage) {
        if (previousPage < 4) {
          for (let j = 1; j < previousPage; j++) {
            paging.pageList.push(j);
          }
        } else {
          paging.pageList.push(1);
          paging.pageList.push(0);
        }
      }
      paging.pageList.push(i);
      if (i === nextPage) {
        if (nextPage > paging.totalPage - 3) {
          for (let j = nextPage + 1; j <= paging.totalPage; j++) {
            paging.pageList.push(j);
          }
        } else {
          paging.pageList.push(0);
          paging.pageList.push(paging.totalPage);
        }
      }
    }
  },

}