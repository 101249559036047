const CONSTANTS = {
  POST_TYPE: {
    POST: 0,
    QUOTE: 1,
    DRAFT: 2
  },
  STATUS: {
    UNDEFINED: -1,
    INACTIVATED: 0,
    ACTIVATED: 1
  }
}
    
export default Object.freeze(CONSTANTS);