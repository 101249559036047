<template>
  <div id="preloader">
    <div id="loader" class="dots-fade">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div id="top" class="s-wrap site-wrapper">
    <router-view />
    <SideMenu />
    <Footer />
  </div>
</template>

<script>
import SideMenu from './components/SideMenu.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    SideMenu,
    Footer
  }
}
</script>

<style></style>
