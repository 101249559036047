<template>
  <div class="s-content content">
    <main class="row content__page">
      <article class="column large-full entry format-standard" v-if="this.postData.id">
        <div class="media-wrap entry__media" v-if="this.postData.thumbnail">
          <div class="entry__post-thumb">
            <div class="thumb__container">
              <img :src="this.postData.thumbnail" alt="">
            </div>
          </div>
        </div>
        <div class="content__page-header entry__header">
          <h1 class="display-1 entry__title">
            {{ this.postData.title }}
          </h1>
          <ul class="entry__header-meta">
            <li class="author">By <a href="#">{{ this.postData.author }}</a></li>
            <li class="date">{{ this.postData.createdTime }}</li>
            <li class="icon icon-pencil" v-if="this.owner" @click="update"></li>
            <!-- <li class="cat-links">
              <a href="#0">Marketing</a>
              <a href="#0">Management</a>
            </li> -->
          </ul>
        </div>
        <div class="entry__content">
          <md-preview :modelValue="this.postData.content" preview-only></md-preview>
          <p class="entry__tags" v-if="this.postData.tags">
            <span>Post Tags</span>
            <span class="entry__tag-list">
              <a href="#" v-for="tag in this.postData.tags" :value="tag.id" :title="tag.description">
                {{ tag.title }}
              </a>
            </span>
          </p>
        </div>
      </article>
      <br>
    </main>
  </div>
</template>

<script>
import { MdPreview } from 'md-editor-v3';

export default {
  name: 'Post',
  components: {
    MdPreview
  },
  data() {
    return {
      isAuthorized: false,
      postId: 0,
      postData: {},
      owner: false
    }
  },
  mounted() {
    this.$nextTick(function() {
      this.isAuthorized = this.$func.checkAuthorization();
      if (this.$route.params.postId) {
        this.postId = this.$route.params.postId;
      }
      this.$func.loading(
        this.loadData,
        this.$func.loadImages
      );
    });
  },
  methods: {

    loadData: async function() {
      const axios = this.$func.getAxios();
      let uri = '/post/'.concat(this.postId);
      let params = {
        params: {
          slug: this.$route.params.slug
        }
      }
      await axios.get(uri, params)
        .then(response => {
          this.postData = response.data.data;
          if (this.isAuthorized) {
            let userInfo = JSON.parse(localStorage.getItem('userInfo'));
            this.owner = userInfo.id == this.postData.userId;
          }
        })
        .catch(error => {
          this.$func.showErrorPopUpV1(error)
            .then(result => {
              if (result.isConfirmed) {
                this.$router.push({path: '/'});
              }
            });
        });
    },

    update: function() {
      this.$router.push({
        name: 'Writer',
        params: {
          id: this.postData.id,
          type: this.postData.type,
          title: this.postData.title,
          content: this.postData.content,
          thumbnail: this.postData.thumbnail ? this.postData.thumbnail : '',
          selectedTags: this.postData.tags ? JSON.stringify(this.postData.tags)  : '[]'
        }
      });
    },

  }
}
</script>

<style scoped>
.md-editor {
  --md-bk-color: none !important;;
}
</style>
