import { createApp } from 'vue'
import App from './App.vue'
import { func } from './assets/js/func.js'
import router from './assets/js/router.js'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App)
app.use(router)
app.use(VueSweetalert2)
app.mount('#app')
app.config.globalProperties.$func = func