import { createRouter, createWebHistory } from 'vue-router'

import Home from '../../components/Home.vue'
import About from '../../components/About.vue'
import Post from '../../components/Post.vue'
import Writer from '../../components/Writer.vue'
import Draft from '../../components/Draft.vue'

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    component: About
  },
  {
    path: '/post/:slug',
    name: 'PostDetail',
    component: Post
  },
  {
    path: '/writer',
    component: Writer
  },
  {
    path: '/writer/:slug',
    name: 'Writer',
    component: Writer
  },
  {
    path: '/draft',
    component: Draft
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

export default router